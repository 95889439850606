/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { strict as e } from "../../core/jsonMap.js";
const o = e()({
    esriGeometryPoint: "point",
    esriGeometryMultipoint: "multipoint",
    esriGeometryPolyline: "polyline",
    esriGeometryPolygon: "polygon"
  }),
  i = e()({
    esriGeometryPoint: "point",
    esriGeometryMultipoint: "multipoint",
    esriGeometryPolyline: "polyline",
    esriGeometryPolygon: "polygon",
    esriGeometryEnvelope: "extent",
    mesh: "mesh"
  });
export { o as featureGeometryTypeKebabDictionary, i as typeKebabDictionary };